.alignmentViewer {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 300px;
    color: #626778; /* minRenderSize; */
    min-width: 800px;
}

.highlightContainer {
    display:flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    margin-bottom: 8px;
    min-height: 56px;
}

.alignmentContainer {
    min-width: 350px; /* $minRenderSize */;
    flex: 1;
    overflow-x: auto;
    position: relative;
    color: #626778; /* minRenderSize; */
}

.heatmapContainer {
    min-width: 350px; /* minRenderSize; */
    margin-left: 350px; /* &labelList; */
    margin-bottom: 16px;
    margin-top: 16px;
    height: 100px;
    min-height: 100px;
    position: relative;

}

.heatmapNavigation {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}

.header {
    min-width: 350px; /* minRenderSize; */
    overflow-y: scroll;
    overflow-x: hidden;
    user-select: none;
    margin-left: 350px; /* &labelList; */
    margin-bottom: 2px;
    height: 30px;
    min-height: 30px;
}

.viewerTable {
    display: flex;
    flex-direction: row;
    min-width: 710px;
    min-height: 200px;
    overflow-y: auto;
}

.selectionHolder {
    position: absolute;
    top: 0;
    bottom: 0;
}

.labelRow {
    min-height: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 15px;
    text-align: left;
    font-size: 11px;
    padding-right: 10px;
}

.labelRowSelected {
    min-height: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 15px;
    text-align: left;
    font-size: 11px;
    background-color: rgba(0, 222, 222, 0.2);
    border-top: solid 1px #0000ff;
    border-bottom: solid 1px #0000ff;
    padding-right: 10px;
    position: relative;
}

.labelButtons {
    background-color: rgba(255,255,255,0.9);
    position: absolute;
    right: 0;
    display: flex;
    flex-direction: row;
    top: 0;
    padding-left: 4px;
    padding-right: 4px;
}

.labelsContainer {
    max-width: 400px;
    overflow-y: auto;
    width: 350px; /* &labelList; */

    cursor: pointer;
}

.row {
    display: flex;
    flex-direction: row;
}

.positionRow {
    display: flex;
    flex-direction: row;
    height: 15px;
}

.aaRect {
    min-width: 15px;
    min-height: 15px;
    width: 15px;
    height: 15px;
    color: #111;
    font-weight: 500;
    text-align: center;
    font-size: 12px;
}

.dnaRect {
    min-width: 15px;
    min-height: 15px;
    width: 15px;
    height: 15px;
    color: #111;
    font-weight: 500;
    text-align: center;
    font-size: 12px;
}

.tick {
    min-width: 45px;
    min-height: 15px;
    width: 45px;
    height: 15px;
    font-weight: 500;
    text-align: left;
    font-size: 8px;
    border-left: #dddddd 1px solid;
    padding-left: 3px;
    padding-top: 3px;
}
