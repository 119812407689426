* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

html {
  min-height: 100%;
  height: 100%;
}

body {
  height: 100%;
  background: #ffffff;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  margin: 0;
  min-height: 100%;
  overflow-y: hidden;
}

#root {
  height: 100%;
}

.root {
  height: 100%;
}

.appWrapper {
  display: flex;
  height: 100%;
  width: 100%;
  min-width: 1200px;
}

.noSelect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                         supported by Chrome and Opera */
}

.biojs_msa_rheader {
  overflow-x: hidden !important;
  overflow-y: hidden !important;
  max-height: 14px;
}
